<script>
export default {
  i18n: {
    messages: {
      fr: {
      },
    },
  },
  computed: {
    status() {
      return this.$store
        .getters['auth/store']
        .affiliation_options
        .status;
    },
  },
};
</script>

<template>
  <div>
    <header class="flex-between-center">
      <h1 class="title mr-3">
        Votre programme d'affiliation
      </h1>
      <p>
        <b-tooltip position="is-left" multilined>
          <template #content>
            <span
              v-t="`affiliates.status_description.${status}`"
            />
          </template>
          <span
            class="tag is-large"
            :class="{
              'is-success': status === 'PUBLIC' || status === 'PRIVATE',
              'is-black': status === 'CLOSED',
            }"
            v-t="`affiliates.status.${status}`"
          />
        </b-tooltip>
      </p>
    </header>

    <div v-if="$store.getters['auth/hasPercentPlan']" class="box is-info content">
      <p>L'affiliation vous permet de vous faire connaître et de vendre plus de formations grâce à la puissance des recommandations.</p>

      <p>En activant votre propre programme d'affiliation, vous récompensez les personnes qui génèrent des ventes pour vous en leur rétribuant un pourcentage des revenus générés !</p>
    </div>

    <GoProWrapper>
      <div class="tabs mb-10">
        <ul>
          <router-link
            tag="li"
            :to="{name: 'affiliates', params: $route.params}"
            exact-active-class="is-active"
          >
            <a>Tableau de bord</a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'affiliates_sales', params: $route.params}"
            exact-active-class="is-active"
          >
            <a>Ventes</a>
          </router-link>
          <router-link
            tag="li"
            :to="{name: 'affiliates_settings', params: $route.params}"
            exact-active-class="is-active"
          >
            <a>Paramètres</a>
          </router-link>
        </ul>
      </div>
      <div>
        <router-view />
      </div>
    </GoProWrapper>
  </div>
</template>
